<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <div class="page-title justify-center text-center px-5">
      <div class="d-flex justify-center mb-5">
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="250px"
          max-width="300px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </div>
      <h2 class="text-1xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Gracias por usar nuestra aplicación</span>
      </h2>

      <div class="d-flex justify-center mt-10">
        <v-btn
          color="primary"
          class="mb-4"
          :to="{name: 'dashboard'}"
        >
          Inicio
        </v-btn>
      </div>

      <div class="d-flex justify-center my-10">
        <div
          class="ma-auto"
          style="max-width: 300px"
        >
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiLockOutline, mdiAccountCancel } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  // props: {
  //   operationCode: {
  //     type: String,
  //     required: true,
  //   },
  //   email: {
  //     type: String,
  //     required: true,
  //   },
  // },

  setup() {
    return {
      loading: ref(false),
      icons: {
        mdiLockOutline,
        mdiAccountCancel,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
